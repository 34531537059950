import React from "react";
import { Button, Card, Col, Input, Row, Space, Table } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { useFirms } from "./firms.hook";
import dayjs from "dayjs";

const Firms = () => {
  const { firms, loading } = useFirms();
  const [search, setSearch] = React.useState("");

  let searchResult = search
    ? firms.filter(
        (item: any) =>
          item.practiceName.toLowerCase().includes(search.toLowerCase()) ||
          item.postCode.toLowerCase().includes(search.toLowerCase()),
      )
    : firms;

  return (
    <Card>
      <h2>Firms</h2>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
          <Input.Search
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ marginBottom: 16 }}
            placeholder="Search for company name or postal code..."
          />
        </Col>
      </Row>
      <Table
        loading={loading}
        scroll={{ x: true }}
        dataSource={searchResult}
        columns={[
          {
            title: "Company Name",
            dataIndex: "companyName",
            key: "id",
            render: (companyName, record: any) => (
              <a href={`/firms/${record.slug}`}>{record.practiceName}</a>
            ),
            sorter: {
              compare: (a, b) => a.practiceName.localeCompare(b.practiceName),
              multiple: 3,
            },
          },
          {
            title: "Postal Code",
            dataIndex: "postCode",
            key: "postCode",
          },
          {
            title: "Category",
            dataIndex: "FirmCategories",
            key: "category",
            render: (categories) => (
              <span>
                {categories
                  ?.map((category: any) => category?.category?.title)
                  .join(", ") || ""}
              </span>
            ),
            onFilter: (value, record) => {
              return record.FirmCategories.some(
                (item: any) => item.category.name === value,
              );
            },
            sorter: (a, b) => a.FirmCategories.length - b.FirmCategories.length,
            filters: [
              { text: "Mixed Use", value: "mixed" },
              { text: "Residential", value: "residential" },
              { text: "Education", value: "education" },
              { text: "Office", value: "office" },
              { text: "Retail", value: "retail" },
              { text: "Industrial", value: "industrial" },
              { text: "Infrastructure", value: "infrastructure" },
              { text: "Hospitality", value: "hospitality" },
            ],
          },
          {
            title: "Subscription",
            dataIndex: "subscription",
            render: (subscription, company) => {
              const isSubscribed = company?.subscriptions.find((item: any) =>
                dayjs(item.endDate).isAfter(dayjs()),
              );
              return (
                <span>
                  {isSubscribed ? (
                    <CheckOutlined
                      style={{
                        color: "green",
                      }}
                    />
                  ) : (
                    <CloseOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </span>
              );
            },
            onFilter: (value, record) => {
              return record.subscriptions.some(
                (item: any) => dayjs(item.endDate).isAfter(dayjs()) === value,
              );
            },
            filters: [
              { text: "Subscribed", value: true },
              { text: "Not Subscribed", value: false },
            ],
          },
          {
            title: "Subscription End Date",
            dataIndex: "subscriptions",
            render: (subscriptions) => {
              const subscription = subscriptions.find((item: any) =>
                dayjs(item.endDate).isAfter(dayjs()),
              );
              return (
                <span>
                  {subscription
                    ? dayjs(subscription.endDate).format("DD/MM/YYYY")
                    : "-"}
                </span>
              );
            },
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (createdAt: string) => (
              <span>{dayjs(createdAt).format("DD/MM/YYYY HH:mm:ss")}</span>
            ),
          },
          {
            title: "Aksiyon",
            render: (value) => (
              <Space size={8}>
                <Button
                  href={`/firms/${value.slug}`}
                  type="primary"
                  onClick={() => {}}
                >
                  <EditOutlined /> Edit
                </Button>
              </Space>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default Firms;
