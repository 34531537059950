import React from "react";
import { Button, Card, Table } from "antd";
import { useSubscriptions } from "./subscriptions.hook";

const Subscriptions = () => {
  const { subscriptions, loading } = useSubscriptions();

  const columns = [
    {
      title: "Subscription ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Company Name",
      dataIndex: "firm",
      key: "companyName",
      render: (firm: { companyName: string; slug: string }) => {
        console.log(firm);
        return (
          <Button type="link" href={`/firms/${firm.slug}`}>
            {firm.companyName || "N/A"}
          </Button>
        );
      },
    },
    {
      title: "Plan",
      dataIndex: "plan",
    },
    {
      title: "Subscription Period",
      key: "date",
      render: (record: any) => {
        return (
          <span>
            {new Date(record.startDate).toLocaleDateString()} -{" "}
            {new Date(record.endDate).toLocaleDateString()}
          </span>
        );
      },
    },
    {
      title: "Stripe Subscription Id",
      dataIndex: "stripeSubscriptionId",
      key: "stripeSubscriptionId",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => new Date(date).toLocaleString(),
    },
  ];
  return (
    <Card>
      <Table
        scroll={{
          x: true,
        }}
        dataSource={subscriptions}
        columns={columns}
        loading={loading}
      />
    </Card>
  );
};

export default Subscriptions;
