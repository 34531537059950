import React from "react";
import { Button, Card, Col, Form, Upload } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "../newBlogPost.module.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, ContentState, EditorState } from "draft-js";

type Props = {
  content: string;
  index: number;
  post: any;
  setPost: (post: any) => void;
  handleUploadProjectImage: (file: any, index: number) => void;
};

const ContentItem = ({
  content,
  index,
  post,
  setPost,
  handleUploadProjectImage,
}: Props) => {
  const [editorState, setEditorState] = React.useState<EditorState | undefined>(
    !content?.startsWith?.("<img")
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(content).contentBlocks),
        )
      : undefined,
  );

  /*useEffect(() => {
    if (content && typeof content === "string") {
      try {
        const contentBlock = htmlToDraft(content);
        if (contentBlock) {
          console.log("contentBlock", contentBlock);

          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
          );
          console.log("contentState", contentState);
          const initialEditorState =
            EditorState.createWithContent(contentState);
          setEditorState(initialEditorState);
        }
      } catch (error) {
        console.error("Error converting HTML to draft:", error);
      }
    }
  }, [content]);*/

  const handleEditorChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const updatedContent = post.content;
    updatedContent[index] = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    setPost({ ...post, content: updatedContent });
  };

  const handleDelete = () => {
    const updatedContent = post.content.filter(
      (_: any, i: number) => i !== index,
    );
    setPost({ ...post, content: updatedContent });
  };

  if (content?.startsWith("<img")) {
    return (
      <Col span={24}>
        <div className={styles.inputGroup}>
          <Form.Item
            required
            label="Image"
            rules={[{ required: true, message: "Please input your image!" }]}
            className={styles.formItem}
          >
            <Upload
              accept="image/*"
              showUploadList={false}
              beforeUpload={(file) => {
                handleUploadProjectImage(file, index);
                return false;
              }}
            >
              <Button>Upload</Button>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            danger
            className={styles.deleteButton}
            onClick={handleDelete}
            icon={<CloseCircleFilled />}
          />
        </div>
      </Col>
    );
  } else {
    return (
      <Col span={24}>
        <div className={styles.inputGroup}>
          <Form.Item
            required
            label="Text"
            rules={[{ required: true, message: "Please input your text!" }]}
            className={styles.formItem}
          >
            <Card>
              <Editor
                defaultEditorState={editorState}
                handlePastedText={() => false}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={handleEditorChange}
              />
            </Card>
          </Form.Item>
          <Button
            type="primary"
            danger
            className={styles.deleteButton}
            onClick={handleDelete}
            icon={<CloseCircleFilled />}
          />
        </div>
      </Col>
    );
  }
};

export default ContentItem;
