import { request } from "../request";

type Content = {
  id: number;
  title?: string;
  content?: {
    text: string;
  }[];
};

type UpdateParamsType = Content[];

export const getHomePageContent = async () => {
  const response = await request.get(`content/home`);
  return response;
};

export const getJoinPageContent = async () => {
  const response = await request.get(`content/join`);
  return response;
};

export const updateJoinPageContent = async (params: UpdateParamsType) => {
  const response = await request.put(`content/join`, params);

  return response;
};

export const updateHomePageContent = async (params: UpdateParamsType) => {
  const response = await request.put(`content/home`, params);
  return response;
};

export const getContactPageContent = async () => {
  const response = await request.get(`content/contact`);
  return response;
};
