import React, { useEffect } from "react";
import styles from "./SearchPageEdit.module.css";

const SearchPageEdit = () => {
  useEffect(() => {}, []);

  return <div className={styles.container}></div>;
};

export default SearchPageEdit;
