import React, { useEffect, useState } from "react";
import styles from "./JoinPageEdit.module.css";
import { Alert, Button, Card, Col, Form, Input, message, Row } from "antd";
import {
  getJoinPageContent,
  updateJoinPageContent,
} from "../../services/content/content.service";
const JoinPageEdit = () => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);

  const handleUpdateJoinPageContent = async (values: any) => {
    try {
      const response = await updateJoinPageContent(fields);
      if (response.success) {
        message.success("Join Page content updated successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetJoinPageContent = async () => {
    try {
      const response = await getJoinPageContent();

      if (response?.code) {
        console.log("Error");
      }
      const contents = response?.data;

      setFields(contents);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetJoinPageContent();
  }, []);

  return (
    <div className={styles.container}>
      <Alert
        type="info"
        message="You can edit the content of the Join page here. {{CurlyBrackets}} are used to color different parts of the text for the design."
      />
      <Row>
        <Col span={24}>
          <Form form={form}>
            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              {fields.map((field: any) => (
                <Col span={24}>
                  <Card>
                    <div className={styles.gutter}>
                      <h4>{field.title}:</h4>
                      <Input
                        value={field.title}
                        onChange={(e) => {
                          setFields((prev: any) => {
                            return prev.map((item: any) => {
                              if (item.title === field.title) {
                                return { ...item, title: e.target.value };
                              }
                              return item;
                            });
                          });
                        }}
                      />

                      {Array.isArray(field?.content)
                        ? field?.content?.map((content: any) => (
                            <Input.TextArea
                              rows={4}
                              value={content.text}
                              onChange={(e) => {
                                setFields((prev: any) => {
                                  return prev.map((item: any) => {
                                    if (item.title === field.title) {
                                      return {
                                        ...item,
                                        content: item.content?.map((c: any) => {
                                          if (c.text === content.text) {
                                            return {
                                              ...c,
                                              text: e.target.value,
                                            };
                                          }
                                          return c;
                                        }),
                                      };
                                    }
                                    return item;
                                  });
                                });
                              }}
                            />
                          ))
                        : Array.isArray(field.content?.boxes)
                          ? field.content.boxes.map((box: any) => (
                              <div className={styles.gutter}>
                                <h4>{box.title}:</h4>
                                <Input
                                  value={box.title}
                                  onChange={(e) => {
                                    setFields((prev: any) => {
                                      return prev.map((item: any) => {
                                        if (item.title === field.title) {
                                          return {
                                            ...item,
                                            content: item.content.boxes.map(
                                              (b: any) => {
                                                if (b.title === box.title) {
                                                  return {
                                                    ...b,
                                                    title: e.target.value,
                                                  };
                                                }
                                                return b;
                                              },
                                            ),
                                          };
                                        }
                                        return item;
                                      });
                                    });
                                  }}
                                />
                                <>
                                  {box.paragraphs?.map((paragraph: any) => (
                                    <Input.TextArea
                                      rows={4}
                                      value={paragraph.text}
                                      onChange={(e) => {
                                        setFields((prev: any) => {
                                          return prev.map((item: any) => {
                                            if (item.title === field.title) {
                                              return {
                                                ...item,
                                                content: item.content.boxes.map(
                                                  (b: any) => {
                                                    if (b.title === box.title) {
                                                      return {
                                                        ...b,
                                                        paragraphs:
                                                          b.paragraphs.map(
                                                            (p: any) => {
                                                              if (
                                                                p.text ===
                                                                paragraph.text
                                                              ) {
                                                                return {
                                                                  ...p,
                                                                  text: e.target
                                                                    .value,
                                                                };
                                                              }
                                                              return p;
                                                            },
                                                          ),
                                                      };
                                                    }
                                                    return b;
                                                  },
                                                ),
                                              };
                                            }
                                            return item;
                                          });
                                        });
                                      }}
                                    />
                                  ))}
                                </>
                              </div>
                            ))
                          : null}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            <Button
              type="primary"
              onClick={() => {
                form.validateFields().then((values) => {
                  handleUpdateJoinPageContent(values);
                });
              }}
              style={{ marginTop: 20 }}
            >
              Save and Publish
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default JoinPageEdit;
