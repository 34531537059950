import React, { useEffect, useState } from "react";
import styles from "./HomePageEdit.module.css";
import { Alert, Button, Card, Col, Form, message, Row } from "antd";
import {
  getHomePageContent,
  updateHomePageContent,
} from "../../services/content/content.service";
import CustomEditor from "../CustomEditor";

const HomePageEdit = () => {
  const [form] = Form.useForm();
  const [titleBox, setTitleBox] = useState<any>(null);
  const [bannerBox, setBannerBox] = useState<any>(null);
  const [ctaBox, setCtaBox] = useState<any>(null);
  const [cookieNotice, setCookieNotice] = useState<any>(null);

  const handleUpdateHomePageContent = async () => {
    try {
      const response = await updateHomePageContent([
        titleBox,
        bannerBox,
        ctaBox,
        cookieNotice,
      ]);
      if (response.success) {
        message.success("Home Page content updated successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetHomePageContent = async () => {
    try {
      const response = await getHomePageContent();

      if (response?.code) {
        console.log("Error");
      }
      const contents = response?.data;

      const titleBoxContent = contents.find(
        (content: any) => content.name === "title_box",
      );

      const bannerBoxContent = contents.find(
        (content: any) => content.name === "banner_box",
      );

      const ctaBoxContent = contents.find(
        (content: any) => content.name === "cta_box",
      );

      const cookieNoticeContent = contents.find(
        (content: any) => content.name === "cookie_notice",
      );

      setTitleBox(titleBoxContent);
      setBannerBox(bannerBoxContent);
      setCtaBox(ctaBoxContent);
      setCookieNotice(cookieNoticeContent);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetHomePageContent();
  }, []);

  return (
    <div className={styles.container}>
      <Alert
        type="info"
        message="You can edit the content of the home page here. {{CurlyBrackets}} are used to color different parts of the text for the design."
      />
      <Row>
        <Col span={24}>
          <Form form={form}>
            <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
              <Col span={24}>
                <Card title="Title Box">
                  {titleBox?.title && (
                    <Form.Item
                      name="title_box_title"
                      initialValue={titleBox?.title}
                      rules={[
                        {
                          required: true,
                          message: "Please enter title box title",
                        },
                      ]}
                    >
                      <CustomEditor
                        content={titleBox?.title}
                        index={null}
                        setContent={(newContent: any) => {
                          setTitleBox({ ...titleBox, title: newContent });
                        }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="title_box"
                    initialValue={titleBox?.content}
                    rules={[
                      {
                        required: true,
                        message: "Please enter title box content",
                      },
                    ]}
                  >
                    {titleBox?.content?.map((content: any, index: number) => (
                      <CustomEditor
                        content={content}
                        index={index}
                        setContent={(newContent: any) => {
                          const newTitleBox = titleBox?.content;
                          newTitleBox[index] = newContent;
                          setTitleBox({ ...titleBox, content: newTitleBox });
                        }}
                      />
                    ))}
                  </Form.Item>
                </Card>
              </Col>
              <Col span={24}>
                <Card title="Banner Box">
                  {bannerBox?.title && (
                    <Form.Item
                      name="banner_box_title"
                      initialValue={bannerBox?.title}
                      rules={[
                        {
                          required: true,
                          message: "Please enter banner box title",
                        },
                      ]}
                    >
                      <CustomEditor
                        content={bannerBox?.title}
                        index={null}
                        setContent={(newContent: any) => {
                          setBannerBox({ ...bannerBox, title: newContent });
                        }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="banner_box"
                    initialValue={bannerBox?.content}
                    rules={[
                      {
                        required: true,
                        message: "Please enter banner box content",
                      },
                    ]}
                  >
                    {bannerBox?.content?.map((content: any, index: number) => (
                      <CustomEditor
                        content={content}
                        index={index}
                        setContent={(newContent: any) => {
                          const newBannerBox = bannerBox?.content;
                          newBannerBox[index] = newContent;
                          setBannerBox({ ...bannerBox, content: newBannerBox });
                        }}
                      />
                    ))}
                  </Form.Item>
                </Card>
              </Col>
              <Col span={24}>
                <Card title="CTA Box">
                  {ctaBox?.title && (
                    <Form.Item
                      name="cta_box_title"
                      initialValue={ctaBox?.title}
                      rules={[
                        {
                          required: true,
                          message: "Please enter CTA box title",
                        },
                      ]}
                    >
                      <CustomEditor
                        content={ctaBox?.title}
                        index={null}
                        setContent={(newContent: any) => {
                          setCtaBox({ ...ctaBox, title: newContent });
                        }}
                      />
                    </Form.Item>
                  )}

                  {ctaBox?.content?.map((content: any, index: number) => (
                    <CustomEditor
                      content={content}
                      index={index}
                      setContent={(newContent: any) => {
                        const newCtaBox = ctaBox?.content;
                        newCtaBox[index] = newContent;
                        setCtaBox({ ...ctaBox, content: newCtaBox });
                      }}
                    />
                  ))}
                </Card>
              </Col>
              <Col span={24}>
                <Card title="Cookie Notice">
                  {cookieNotice?.title && (
                    <Form.Item
                      name="cookie_notice_title"
                      initialValue={cookieNotice?.title}
                      rules={[
                        {
                          required: true,
                          message: "Please enter Cookie Notice title",
                        },
                      ]}
                    >
                      <CustomEditor
                        content={cookieNotice?.title}
                        index={null}
                        setContent={(newContent: any) => {
                          setCookieNotice({
                            ...cookieNotice,
                            title: newContent,
                          });
                        }}
                      />
                    </Form.Item>
                  )}

                  {cookieNotice?.content?.map((content: any, index: number) => (
                    <CustomEditor
                      content={content}
                      index={index}
                      setContent={(newContent: any) => {
                        const newCookieNotice = cookieNotice?.content;
                        newCookieNotice[index] = newContent;
                        setCookieNotice({
                          ...cookieNotice,
                          content: newCookieNotice,
                        });
                      }}
                    />
                  ))}
                </Card>
              </Col>
            </Row>
            <Button
              type="primary"
              onClick={() => {
                handleUpdateHomePageContent();
              }}
              style={{ marginTop: 20 }}
            >
              Save and Publish
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default HomePageEdit;
