import React from "react";
import { Button, Col, Form, Input, Row, Upload } from "antd";
import styles from "../newBlogPost.module.css";
import ContentItem from "./ContentItem";

type Props = {
  post: any;
  setPost: (post: any) => void;
  handleUploadProjectImage: (file: any, index: number) => void;
  handleUploadCoverImage: (file: any) => void;
};

const ContentForm = ({
  post,
  setPost,
  handleUploadProjectImage,
  handleUploadCoverImage,
}: Props) => {
  const addContent = (type: string) => {
    const newContent = type === "text" ? "<p></p>" : "<img src='' alt='' />";
    setPost({
      ...post,
      content: [...post.content, newContent],
    });
  };

  return (
    <Form layout="vertical" className={styles.form}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            required
            label="Cover Image"
            rules={[
              { required: true, message: "Please input your cover image!" },
            ]}
            className={styles.formItem}
          >
            <Upload
              accept="image/*"
              showUploadList={false}
              beforeUpload={(file) => {
                handleUploadCoverImage(file);
                return false;
              }}
            >
              <Button>Upload</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            required
            label="Slug"
            rules={[{ required: true, message: "Please input your slug!" }]}
            className={styles.formItem}
          >
            <Input
              onChange={(e) => setPost({ ...post, slug: e.target.value })}
              value={post.slug}
              placeholder="Slug"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            required
            label="Title"
            rules={[{ required: true, message: "Please input your title!" }]}
            className={styles.formItem}
          >
            <Input
              onChange={(e) => setPost({ ...post, title: e.target.value })}
              value={post.title}
              placeholder="Title"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            required
            label="Meta Title"
            rules={[
              { required: true, message: "Please input your meta title!" },
            ]}
            className={styles.formItem}
          >
            <Input
              onChange={(e) => setPost({ ...post, metaTitle: e.target.value })}
              value={post.metaTitle}
              placeholder="Meta Title"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            required
            label="Meta Description"
            rules={[
              {
                required: true,
                message: "Please input your meta description!",
              },
            ]}
            className={styles.formItem}
          >
            <Input
              onChange={(e) =>
                setPost({ ...post, metaDescription: e.target.value })
              }
              value={post.metaDescription}
              placeholder="Meta Description"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div>
            {post.content.map((content: string, index: number) => (
              <ContentItem
                key={index}
                content={content}
                index={index}
                post={post}
                setPost={setPost}
                handleUploadProjectImage={handleUploadProjectImage}
              />
            ))}
          </div>
        </Col>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "16px",
              gap: "8px",
            }}
          >
            <Button type="primary" onClick={() => addContent("text")}>
              Add Text
            </Button>
            <Button type="primary" onClick={() => addContent("image")}>
              Add Image
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ContentForm;
