import { config } from "../config";
import { message } from "antd";

const getAccessToken = () => {
  return localStorage.getItem("@accessToken");
};

/*const getRefreshToken = () => {
  return localStorage.getItem("@refreshToken");
};*/

const handleResponse = async (response: Response, fullResponse?: boolean) => {
  if (response.status === 500) {
    message.error("Internal server error");

    return null;
  }
  console.log("response", response);
  if (response.status === 401 || response.status === 403) {
    const res = await response.json();
    if (res.message) {
      message.error(res.message);
      localStorage.removeItem("@accessToken");
      localStorage.removeItem("@refreshToken");
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      message.error("Token expired or invalid. Redirecting to login page...");
    }
  } else {
    const res = await response.json();
    if (res.data) {
      if (fullResponse) {
        return res;
      }
      return res.data;
    } else {
      return res;
    }
  }
};

const get = async (url: string, headers?: any) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const requestUrl = `${config.apiUrl}/${url}`;
  try {
    const response = await fetch(requestUrl, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.log("error", error);
    message.error("Error on GET request");
  }
};

const post = async (
  url: string,
  body: any,
  headers?: any,
  isFormData?: boolean,
  fullResponse?: boolean,
) => {
  const defaultHeaders: {
    "Content-Type"?: string;
    Authorization: string;
  } = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  };

  if (headers?.["Content-Type"] === "multipart/form-data") {
    if (defaultHeaders["Content-Type"]) {
      delete defaultHeaders["Content-Type"];
    }
    if (headers["Content-Type"]) {
      delete headers["Content-Type"];
    }
  }
  const requestUrl = `${config.apiUrl}/${url}`;
  try {
    let response = await fetch(requestUrl, {
      method: "POST",
      body: isFormData ? body : JSON.stringify(body),
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
    });
    let result = await handleResponse(response, fullResponse);
    if (result === "retry") {
      response = await fetch(requestUrl, {
        method: "POST",
        body: isFormData ? body : JSON.stringify(body),
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${getAccessToken()}`,
          ...(headers || {}),
        },
      });
      result = await handleResponse(response, false);
    }
    return result;
  } catch (error) {
    console.log("error", error);
    message.error("Error on POST request");
  }
};

const put = async (url: string, body: any, headers?: any) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const requestUrl = `${config.apiUrl}/${url}`;
  try {
    const response = await fetch(requestUrl, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.log("error", error);
    message.error("Error on PUT request");
  }
};

const del = async (url: string, body?: any, headers?: any) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const requestUrl = `${config.apiUrl}/${url}`;
  try {
    const response = await fetch(requestUrl, {
      method: "DELETE",
      body: JSON.stringify(body),
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.log("error", error);
    message.error("Error on DELETE request");
  }
};

export const request = {
  get,
  post,
  put,
  delete: del,
};
